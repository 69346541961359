<template>
  <v-container>
    <Loading v-if="isLoading" />
    <v-row ref="change">
      <v-col
        cols="12"
        class="pb-0">
        <v-card
          class="mb-4"
          elevation="0"
          outlined>
          <v-card-subtitle class="d-flex justify-space-between">
            <h4 class="header-primary text-bold">
              Order ID
            </h4>
            <h4 class="data">
              {{ $route.params.orderId }}
            </h4>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-subtitle>
            <v-row>
              <v-col cols="12">
                <h4 class="header-primary text-bold">
                  {{ $t('booking.oldSchedule') }}
                </h4>
              </v-col>
            </v-row>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-space-between">
                  <h4 class="header-secondary text-bold">
                    {{ $t('global.date') }}
                  </h4>
                  <h4 class="data">
                    {{ order.schedule.date | formatMiniDate }}
                  </h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="pt-0 d-flex justify-space-between">
                  <h4 class="header-secondary text-bold">
                    {{ $t('global.time') }}
                  </h4>
                  <h4 class="data">
                    {{ formatSchedule(order.schedule.startTime, order.schedule.endTime) }}
                  </h4>
                </v-col>
              </v-row>
            </v-container>
          </v-card-subtitle>
          <v-divider></v-divider>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="newSchedule.date"
      class="d-flex justify-center mt-0">
      <v-icon
        large
        color="green">
        mdi-repeat mdi-rotate-90
      </v-icon>
    </v-row>
    <v-row v-if="newSchedule.date">
      <v-col
        cols="12"
        class="mt-0">
        <v-card
          class="mb-4"
          elevation="0"
          outlined>
          <v-card-subtitle class="d-flex justify-space-between">
            <h4 class="header-primary text-bold">
              Order ID
            </h4>
            <h4 class="data">
              {{ $route.params.orderId }}
            </h4>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-subtitle>
            <v-row>
              <v-col cols="12">
                <h4 class="header-primary text-bold">
                  {{ $t('booking.newSchedule') }}
                </h4>
              </v-col>
            </v-row>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-space-between">
                  <h4 class="header-secondary text-bold">
                    {{ $t('global.date') }}
                  </h4>
                  <h4
                    v-if="newSchedule.date"
                    class="data">
                    {{ newSchedule.date | formatMiniDate }}
                  </h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="pt-0 d-flex justify-space-between">
                  <h4 class="header-secondary text-bold">
                    {{ $t('global.time') }}
                  </h4>
                  <h4
                    v-if="newSchedule.startTime && newSchedule.endTime"
                    class="data">
                    {{ formatSchedule(newSchedule.startTime, newSchedule.endTime) }}
                  </h4>
                </v-col>
              </v-row>
            </v-container>
          </v-card-subtitle>
          <v-divider></v-divider>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="newSchedule.date">
      <v-col
        cols="6"
        class="pt-0">
        <v-btn
          color="warning"
          depressed
          style="text-transform: none"
          block
          @click="clearNewSchedule">
          {{ $t('booking.otherSchedule') }}
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        class="pt-0">
        <v-btn
          color="success"
          depressed
          style="text-transform: none"
          block
          @click="submitUpdate">
          {{ $t('booking.confirm') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!newSchedule.date">
      <v-col cols="12">
        <v-dialog v-model="isSchedule">
          <v-card>
            <v-card-title class="header justify-center mb-4">
              <span class="font-weight-bold">{{ formatDate(dateMock) }}</span>
            </v-card-title>
            <v-container>
              <div
                v-for="(item, index) in items"
                :key="index"
                class="header px-4 mb-6">
                <v-row>
                  <v-col
                    class="mt-1"
                    cols="6">
                    {{ formatSchedule(item.startTime, item.endTime) }}
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-btn
                      v-if="item.slot >= item.max || item.isOpen === false"
                      width="120"
                      dark
                      depressed
                      color="#af5a5f">
                      {{ $t('global.full') }}
                    </v-btn>
                    <v-btn
                      v-else
                      width="120"
                      dark
                      depressed
                      color="#23b574"
                      @click="setTime(item.scheduleId, item.startTime, item.endTime, item.max, item.slot)">
                      {{ $t('global.blank') }} {{ item.max - item.slot }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card>
        </v-dialog>
        <Calendar
          v-if="reRender"
          ref="calendar"
          :event="allEvent"
          :schedule="allSchedule"
          :fulldate="allDateFull"
          @input="getSchedule($event)"
        />
        <v-dialog
          v-model="isValidate"
          transition="dialog-bottom-transition">
          <v-card>
            <v-card-title class="text-center red white--text lighten-2">
              <v-icon
                class="mr-4"
                color="white">
                mdi-alert-circle
              </v-icon>
              {{ $t('global.warning') }}
            </v-card-title>

            <v-card-text class="font-weight-bold pt-6">
              {{ $t('booking.validateMax') }}
            </v-card-text>
            <v-card-text class="pb-2 d-flex justify-end">
              <v-btn
                color="red"
                text
                @click="isValidate = false">
                {{ $t('booking.confirm') }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import dayjs from 'dayjs'
import Loading from '@/components/Loading.vue'
import formatDate from '@/utils/formatDate'
import Calendar from '@/components/Calendar.vue'
import axios from '../../../../utils/axios.js'

export default {
  components: { Loading, Calendar },
  data () {
    return {
      isLoading: false,
      isSchedule: false,
      isValidate: false,
      allEvent: [],
      order: {
        schedule: {
          date: '',
          startTime: '',
          endTime: ''
        }
      },
      oldSchedule: {},
      newSchedule: {},
      allSchedule: [],
      allDateFull: [],
      items: [],
      dateMock: '',
      reRender: true
    }
  },
  async mounted () {
    await this.getOrder()
    await this.getEvent()
    this.scheduleBook()
  },
  methods: {
    async getEvent () {
      try {
        if (this.$route.query.type === 'event') {
          this.reRender = false
          const { data: { data } } = await axios.get('/event')
          this.allEvent = data
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.reRender = true
      }
    },
    async getOrder () {
      try {
        this.isLoading = true
        const { type } = this.$route.query
        const normalUrl = `${process.env.VUE_APP_API_URL}/order/${this.$route.params.orderId}`
        const groupUrl = `${process.env.VUE_APP_API_URL}/order-group/${this.$route.params.orderId}`
        let url
        switch (type) {
          case 'normal':
            url = normalUrl
            break
          case 'group':
            url = groupUrl
            break
          case 'event':
            url = normalUrl
            break
          default:
            break
        }
        const { data: { data } } = await axios({
          method: 'get',
          url
        })
        this.order = data
        await this.getAllSchedule()
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    async getAllSchedule () {
      try {
        this.isLoading = true
        this.reRender = false
        const { type } = this.$route.query
        const { data: { data, additional } } = await axios.get(`${process.env.VUE_APP_API_URL}/schedule?branch=${this.order.branch}&type=${type === 'group' ? 'normal' : type}`)

        const datas = data.filter((item) => item.scheduleId !== this.order.schedule.scheduleId)
        let additionals = additional.full
        if (additionals.length > 0) {
          additionals = additionals.filter((item) => item !== this.order.schedule.date)
        }

        this.allSchedule = datas
        this.allDateFull = additionals
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
        this.reRender = true
      }
    },
    async getSchedule (event) {
      try {
        this.isLoading = true
        const { type } = this.$route.query
        const { data: { data } } = await this.axios.get(`${process.env.VUE_APP_API_URL}/schedule/date/search?date=${event.date}&branch=${this.order.branch}&type=${type === 'group' ? 'normal' : type}`)
        // const { data: { data } } = await this.axios.get(`${process.env.VUE_APP_API_URL}/schedule/date/search?date=${event}`)
         const datas = data.filter((item) => item.scheduleId !== this.order.schedule.scheduleId)
        this.items = datas
        this.dateMock = event.date
        this.isSchedule = true
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
    formatDate (date) {
      return formatDate.default(date)
    },
    setTime (scheduleId, startTime, endTime, max, slot) {
      const { type } = this.$route.query
      if (type === 'group') {
        if (this.order.joinQty > (max - slot)) {
          this.isValidate = true
          this.isSchedule = false
        } else {
          this.newSchedule = {
            scheduleId,
            startTime,
            endTime,
            max,
            slot,
            date: this.dateMock
        }
        this.confirmSchedule()
        }
      }
      if (type !== 'group') {
        if (this.order.participant.length > (max - slot)) {
          this.isValidate = true
          this.isSchedule = false
        } else {
          this.newSchedule = {
            scheduleId,
            startTime,
            endTime,
            max,
            slot,
            date: this.dateMock
        }
        this.confirmSchedule()
        }
      }
    },
    confirmSchedule () {
      const el = this.$refs.change
      setTimeout(() => {
        el.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
      }, 200)
      this.isSchedule = false
    },
    clearNewSchedule () {
      this.newSchedule = {}
      setTimeout(() => {
        window.scroll({
        top: 500,
        behavior: 'smooth'
      })
      }, 200)
    },
    async submitUpdate () {
      try {
        this.isLoading = true
        const { type } = this.$route.query
        const normalUrl = `${process.env.VUE_APP_API_URL}/order/change/${this.$route.params.orderId}`
        const groupUrl = `${process.env.VUE_APP_API_URL}/order-group/change/${this.$route.params.orderId}`
        let url
        switch (type) {
          case 'normal':
            url = normalUrl
            break
          case 'event':
            url = normalUrl
            break
          case 'group':
            url = groupUrl
            break
          default:
            break
        }
        let participant = 0
        if (type !== 'group') {
          participant = this.order.participant.length
        } else {
          participant = this.order.joinQty
        }
        await axios({
          method: 'patch',
          url,
          data: {
            oldScheduleId: this.order.schedule.scheduleId,
            newScheduleId: this.newSchedule.scheduleId,
            participant }
        })
        this.$router.push('/liff/history')
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    formatSchedule (startTime, endTime) {
      if (startTime === '00:00' && endTime === '23:59') {
        return 'Allday'
      }
      return `${startTime} - ${endTime}`
    },
    scheduleBook () {
      // const diff = dayjs(this.order.schedule.date).diff(dayjs(), 'day')
      // if (this.order.type !== 'group' && diff < 3) {
      //   this.$router.push('/liff/history')
      // }
      // if (this.order.type === 'group' && diff < 7) {
      //   this.$router.push('/liff/history')
      // }
    }
  }
}
</script>

<style scoped>
.header-primary {
  font-size: 18px;
  font-weight: 500;
}
.header-primary-small {
  font-size: 16px;
  font-weight: 500;
}
.header-secondary {
  font-size: 16px;
  font-weight: 500;
}
.data {
  font-size: 16px;
  font-weight: 500;
}
.text-bold {
  font-weight: 600;
}
</style>
